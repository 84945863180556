import React from "react"
import Layout from "../components/layout"
import RenderHtml from "../components/RenderHtml"

export default ({ pageContext: { event }, location }) => {
  const [
    featuredEventDay,
    featuredEventMonth,
    featuredEventYear,
  ] = event.acf.date.split("/")

  const featuredEventDate = new Date(
    featuredEventYear,
    featuredEventMonth - 1,
    featuredEventDay
  ).toLocaleString("en-us", { month: "long", day: "2-digit", year: "numeric" })

  const ticketLink = event.acf.ticket_url ? (
    <a
      className="button"
      style={{ marginBottom: "1rem" }}
      href={event.acf.ticket_url}
      target="_blank"
      rel="noopener noreferrer"
    >
      Buy Tickets
    </a>
  ) : null

  return (
    <Layout location={location}>
      <div className="container">
        <div className="featuredEvent">
          <div className="featuredEvent__image">
            <img src={event.acf.image} alt="" />
          </div>
          <div className="featuredEvent__info">
            <div className="featuredEvent__date">{featuredEventDate}</div>
            <div
              className="featuredEvent__title"
              dangerouslySetInnerHTML={{ __html: event.title.rendered }}
            ></div>
            <div className="featuredEvent__time">{event.acf.start_time}</div>
            <RenderHtml
              className="featuredEvent__description"
              html={event.acf.address}
            />
            {ticketLink}
            <RenderHtml
              className="featuredEvent__description"
              html={event.acf.description}
            />
            {/* <div
              className="featuredEvent__description"
              dangerouslySetInnerHTML={{
                __html: event.acf.description,
              }}
            /> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}
